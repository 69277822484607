
import Icon from '@/components/Icon.vue'
import { isShield } from '@/decorators/withoutCryptos'
import i18n, { languages } from '@/i18n'
import TheLoginView from '@/pages/login/components/TheLoginView.vue'
import Page from '@/provider/Page.vue'
import { roam } from '@/state/actions'
import Pic from '@/components/Pic.vue'
import { computed, defineComponent, shallowRef } from 'vue'
import * as R from 'ramda'
import Swipe from '@/components/Swipe.vue'

export default defineComponent({
  name: 'LoginIndex',
  components: { TheLoginView, Icon, Pic, Page, Swipe },
  props: {
    isLogin: {
      type: String,
      default: '0',
    },
  },
  setup (props, ctx) {
    const bannerConfirmed = shallowRef(false)

    const tryNow = () => {
      roam()
      ctx.emit('close')
    }

    const locale = computed(() => R.find(R.propEq('lang', i18n.locale.value), languages))

    const banners = [
      {
        imageurl: 'login/cover_1',
        text: 'cover_13',
      },
      {
        imageurl: 'login/cover_2',
        text: 'cover_14',
      },
      {
        imageurl: 'login/cover_3',
        text: 'cover_11',
      },
      {
        imageurl: 'login/cover_4',
        text: 'cover_12',
      },
    ]

    isShield().then(result => {
      if (!result) {
        banners.splice(0, 2, ...[
          {
            imageurl: 'login/cover_1_0',
            text: 'cover_9',
          },
          {
            imageurl: 'login/cover_2_0',
            text: 'cover_10',
          },
        ])
      }

      bannerConfirmed.value = true
    })

    return {
      bannerConfirmed,
      locale,
      banners,
      tryNow,
    }
  },
})
