
import { googleLogin } from '@/features/googleLogin/googleLogin.api'
import { login } from '@/modules/user/user'
import { defineComponent, onBeforeMount, shallowRef } from 'vue'
import SignIn from '@/pages/login/SignIn.vue'
import SignUp from '@/pages/register/SignUp.vue'
import { callService } from '@/common/jsBridge.api'

export default defineComponent({
  name: 'TheLoginView',
  components: { SignIn, SignUp },
  props: {
    isLogin: {
      type: String,
      required: true,
    },
  },
  setup (props, ctx) {
    const actIndex = shallowRef(Number(props.isLogin === '1'))
    const switchTab = (index: number) => {
      actIndex.value = index
    }

    onBeforeMount(() => {
      (window as any).google?.accounts.id.initialize({
        client_id: '499094292366-nvju2cn3ltmdihsmqla9bn8dafve5k4g.apps.googleusercontent.com',
        callback (resp: any) {
          googleLogin({ idToken: resp.credential })
            .then(resp => {
              login(resp)
              ctx.emit('close')
            })
        },
      })

      ;(window as any).google?.accounts.id.prompt()
    })

    return {
      actIndex,
      switchTab,
      callService,
    }
  },
})
