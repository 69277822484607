
import { defineComponent, onMounted } from 'vue'

export default defineComponent({
  name: 'GoggleLoginButton',
  setup () {
    onMounted(() => {
      (window as any).google?.accounts?.id.renderButton(document.getElementById('google'), {
        theme: 'outline',
        size: 'large',
        width: '320px',
        text: 'continue_with',
        logo_alignment: 'left',
      })
    })
  },
})
