
import Button from '@/components/Button.vue'
import Pic from '@/components/Pic.vue'
import GoggleLoginButton from '@/features/googleLogin/GoogleLoginButton.vue'
import TheMobileInput from '@/pages/login/components/TheMobileInput.vue'
import ThePasswordInput from '@/pages/login/components/ThePasswordInput.vue'
import { loginFormSchema, useLogin } from '@/pages/login/login'
import { roam } from '@/state/actions'
import Form from 'common/form/Form.vue'
import { defineComponent, onMounted, onUnmounted, ref, shallowRef } from 'vue'
import { useRouter } from 'vue-router'

export default defineComponent({
  name: 'SignIn',
  components: { GoggleLoginButton, Pic, ThePasswordInput, TheMobileInput, Button, Form },
  setup () {
    const { login, progress } = useLogin()
    const router = useRouter()
    const form = ref<HTMLFormElement | null>(null)
    const countryCode = shallowRef()

    const ccChange = (code: string) => {
      countryCode.value = code
    }

    const tryNow = () => {
      roam()
      router.push('/')
    }

    onMounted(() => {
      window.addEventListener('keydown', (e: KeyboardEvent) => {
        if (e.keyCode === 13) {
          (form.value as HTMLFormElement).submit()
        }
      })
    })

    onUnmounted(() => {
      window.removeEventListener('keydown', () => {
        // remove listener
      })
    })

    return {
      form,
      roam: tryNow,
      login,
      progress,
      loginFormSchema,
      countryCode,
      ccChange,
      submit () {
        (form.value as HTMLFormElement).submit()
      },
    }
  },
})
